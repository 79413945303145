@import "./Materia/variables";
@import "~bootstrap/scss/bootstrap";
@import "./Materia/bootswatch";
@import "./select.scss";
@import "./spinner.scss";

:root {
    --primary-color: #{$blue};
}

// Adds a class to make YouTube videos responsive
.youtube-video {
    width: 100%;
    height: 270px;
}

.input-group-prepend,
.input-group-append {
    .input-group-text {
        padding: 0;
        margin-top: auto;
        padding-bottom: 0.5em;
        color: darkgrey;
    }
}

@include media-breakpoint-only(md) {
    .youtube-video {
        height: 371.25px;
    }
}

@include media-breakpoint-only(lg) {
    .youtube-video {
        height: 506.25px;
    }
}
@include media-breakpoint-only(xl) {
    .youtube-video {
        height: 607.5px;
    }
}

.multi-remove-button {
    color: $danger;
}

.fade-enter {
    opacity: 0;
    margin-left: -10vw;
}

.fade-enter.fade-enter-active {
    transition: opacity 200ms linear, margin-left 150ms ease-out;
    opacity: 1;
    margin-left: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
    overflow: hidden;
}

.unfade-enter {
    opacity: 0;
    margin-left: 10vw;
}

.unfade-enter.unfade-enter-active {
    transition: opacity 200ms linear, margin-left 150ms ease-out;
    opacity: 1;
    margin-left: 0;
}

.unfade-exit {
    opacity: 1;
}

.unfade-exit.unfade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
    overflow: hidden;
}

.transition-group {
    position: relative;

    > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}

.btn-group-toggle > .btn {
    text-transform: none;
    font-weight: 500;
}

.form-control {
    padding-bottom: 0;
}

input:not(.form-control),
input:not(.form-control):focus {
    box-shadow: none;
}

// // bhavin wrote this :)
// .input-field {
//     position: relative;
//     width: 250px;
//     height: 44px;
//     line-height: 44px;

//     > label {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         color: #d3d3d3;
//         transition: 0.2s all;
//         cursor: text;
//     }
//     > input {
//         width: 100%;
//         border: 0;
//         outline: 0;
//         padding: 0.5rem 0;
//         border-bottom: 2px solid #d3d3d3;
//         box-shadow: none;
//         color: #111;

//         :invalid {
//             outline: 0;
//             // color: #FF2300;
//             //   border-color: #FF2300;
//         }
//         :focus,
//         :valid {
//             border-color: #00dd22;
//         }

//         :focus,
//         :focus ~ label,
//         :valid ~ label {
//             font-size: 14px;
//             top: -24px;
//             color: #00dd22;
//         }
//     }
// }

.icon-upload {
    display: block;

    &:after {
        display: block;
        content: "";
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        z-index: 999;
        transition: background-color 0.2s;
        filter: invert(100%);
    }
    &:hover {
        &:after {
            background-color: rgba(0, 0, 0, 0.4);
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" strokeWidth="2" stroke="black" strokeLinecap="round" strokeLinejoin="round"> <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z" /> <circle cx="12" cy="13" r="4"  /> </svg>');
            padding: 34px;
            cursor: pointer;
        }
    }
}

table > tbody:first-child > tr:first-child > td {
    border-top: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    //border: 1px solid green;
    // -webkit-text-fill-color: green;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #828a98;
}

.remove-text-transform {
    text-transform: unset !important;
}
